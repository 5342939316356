import { Component, inject, input, output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputOtpModule } from 'primeng/inputotp';
import { OrderOtpService } from 'src/app/services/order-otp.service';

@Component({
  selector: 'app-order-otp',
  standalone: true,
  imports: [InputOtpModule, ReactiveFormsModule, ButtonModule],
  templateUrl: './order-otp.component.html',
})
export class OrderOtpComponent {
  orderData = input.required<{
    orderId: number;
    phone: string;
  }>();
  onSuccess = output<void>();
  onError = output<void>();
  confirmOtp = inject(OrderOtpService).confirmOtp();
  confirmOtpResult = this.confirmOtp.result;

  otpForm = new FormGroup({
    otp: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
      Validators.pattern(/^[0-9]*$/),
    ]),
  });

  onSubmit() {
    if (!this.otpForm.valid) {
      return;
    }

    this.confirmOtp.mutate(
      {
        code: this.otpForm.value.otp!,
        orderId: this.orderData().orderId.toString(),
        phone: this.orderData().phone,
      },
      {
        onSuccess: () => {
          this.onSuccess.emit();
        },
        onError: () => {
          this.onError.emit();
        },
      }
    );
  }
}
