import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  injectMutation,
  injectQuery,
  injectQueryClient,
  queryOptions,
} from '@ngneat/query';
import { QUERY_KEYS } from '../lib/constants';
import { environment } from '@environment/environment';
import { CoinOrderListResponse } from '@model/orders';
import { Order } from '@model/order';
import { OrderFilters } from '@model/order-filters';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #queryClient = injectQueryClient();
  messageService = inject(MessageService);

  constructor() {}

  getOrders(options?: {
    params?: {
      filter: OrderFilters;
    };
  }) {
    return this.#query({
      ...this.getOrdersOptions({ ...options }),
    });
  }

  getOrdersOptions(options?: {
    params?: {
      filter: OrderFilters;
    };
  }) {
    const params = options?.params?.filter
      ? {
          filter: JSON.stringify(options?.params?.filter),
        }
      : {
          filter: JSON.stringify({
            pageSize: null,
            pageNumber: null,
            fio: null,
            iin: null,
            status: null,
          }),
        };

    return queryOptions<CoinOrderListResponse>({
      queryKey: [QUERY_KEYS.ORDERS, params],
      queryFn: () =>
        this.#http.get<CoinOrderListResponse>(
          environment.apiUrlPrefix + 'nbrk/get-coin-order-list',
          {
            withCredentials: true,
            params: params,
          }
        ),
    });
  }

  getOrderById(id: number) {
    return this.#query({
      queryKey: [QUERY_KEYS.ORDERS, id],
      queryFn: () =>
        this.#http.get<Order>(
          environment.apiUrlPrefix + 'nbrk/get-coin-order',
          {
            withCredentials: true,
            params: { id },
          }
        ),
    });
  }

  getOrdersMutation() {
    return this.#mutation({
      mutationFn: (options?: {
        params?: {
          filter: OrderFilters;
        };
      }) => {
        const params = options?.params?.filter
          ? {
              filter: JSON.stringify(options?.params?.filter),
            }
          : {
              filter: JSON.stringify({
                pageSize: null,
                pageNumber: null,
                fio: null,
                iin: null,
                status: null,
              }),
            };

        return this.#http.get<CoinOrderListResponse>(
          environment.apiUrlPrefix + 'nbrk/get-coin-order-list',
          {
            withCredentials: true,
            params,
          }
        );
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries();
      },
    });
  }

  receiveCoin() {
    return this.#mutation({
      mutationFn: ({ id }: { id: number }) =>
        this.#http.get<void>(environment.apiUrlPrefix + 'nbrk/coin-received', {
          withCredentials: true,
          params: { id },
        }),
      onSuccess: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Успех',
          detail: 'Монета выдана',
        });
      },
      onError: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: 'Не удалось выдать монету',
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries();
      },
    });
  }
}
