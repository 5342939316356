<p-toast />
<p-confirmDialog />
<app-sidebar-layout>
  <div sidebar class="h-full">
    <app-sidebar-content></app-sidebar-content>
  </div>
  <div main-content class="h-full">
    <router-outlet></router-outlet>
  </div>
</app-sidebar-layout>
