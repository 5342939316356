<form
  [formGroup]="filtersForm"
  class="flex items-center flex-wrap gap-4 w-full"
  (ngSubmit)="submitForm()"
>
  <p-iconField iconPosition="left">
    <p-inputIcon styleClass="pi pi-search" />
    <input type="text" pInputText placeholder="ФИО" formControlName="fio" />
  </p-iconField>
  <p-iconField iconPosition="left">
    <p-inputIcon styleClass="pi pi-search" />
    <input type="text" pInputText placeholder="ИИН" formControlName="iin" />
  </p-iconField>
  <p-dropdown
    [options]="statuses"
    placeholder="Статус"
    formControlName="status"
  />
  @if (shouldDisplayBranchesFilter()) {

  <p-dropdown
    [options]="branchDropdownOptions()"
    placeholder="Филиал"
    formControlName="branch"
    optionLabel="name"
    optionValue="code"
  />
  }
  <p-button type="submit" label="Поиск"></p-button>
  <p-button
    type="button"
    label="Сброс"
    [outlined]="true"
    (click)="resetFilters()"
  ></p-button>
</form>
