<app-page-layout title="Филиалы НБРК">
  <section class="h-full flex flex-col items-start gap-8">
    @if (branches().isLoading) {
    <p-skeleton height="500px" class="w-full" />

    } @if (!!branches().error) {
    <p class="text-red-400">
      {{ branches().error?.message || "Что-то пошло не так" }}
    </p>

    } @if (branches().data) {
    <p-table
      [value]="branches().data || []"
      [columns]="cols"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-sm"
      scrollHeight="65vh"
      [scrollable]="true"
      class="w-full"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          @for (item of columns; track item.id) {
          <th class="min-w-24">{{ item.header }}</th>
          }
          <th class="w-5"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          @for (col of columns; track col.field) {
          <td>{{ rowData[col.field] }}</td>
          }
          <td>
            <a
              class="text-primary underline hover:opacity-80 transition-colors"
              [routerLink]="rowData.id"
            >
              Детали
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
    }

    <a routerLink="/create-branch" class="p-button font-bold">
      Добавить филиал
    </a>
  </section>
</app-page-layout>
