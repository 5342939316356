import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { injectQuery, injectMutation, injectQueryClient } from '@ngneat/query';
import { MessageService } from 'primeng/api';
import { QUERY_KEYS } from '../lib/constants';
import { environment } from '@environment/environment';
import { Branch } from '@model/branch';
import { Router } from '@angular/router';

type SaveBranchBody = {
  id: number;
  name: string;
  city: string;
  address: string;
};

type UpdateBranchBody = SaveBranchBody;

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #messageService = inject(MessageService);
  #queryClient = injectQueryClient();
  #router = inject(Router);

  constructor() {}

  getBranchList() {
    return this.#query({
      queryKey: [QUERY_KEYS.BRANCHES],
      queryFn: () =>
        this.#http.get<Branch[]>(
          environment.apiUrlPrefix + 'nbrk/branch-list',
          {
            withCredentials: true,
          }
        ),
    });
  }

  getBranch(id: number) {
    return this.#query({
      queryKey: [QUERY_KEYS.BRANCH, id],
      queryFn: () =>
        this.#http.get<Branch>(environment.apiUrlPrefix + 'nbrk/branch', {
          withCredentials: true,
          params: { id },
        }),
    });
  }

  saveBranch() {
    return this.#mutation({
      mutationFn: (body: SaveBranchBody) =>
        this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/save-branch',
          new URLSearchParams({
            request: JSON.stringify(body),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        ),

      onSuccess: () => {
        this.#messageService.add({
          severity: 'success',
          detail: 'Филиал добавлен',
        });
      },
      onError: (error) => {
        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: 'Что-то пошло не так',
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BRANCHES],
        });
      },
    });
  }

  updateBranch() {
    return this.#mutation({
      mutationFn: (body: UpdateBranchBody) =>
        this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/update-branch',
          new URLSearchParams({
            request: JSON.stringify(body),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        ),

      onSuccess: () => {
        this.#messageService.add({
          severity: 'success',
          detail: 'Филиал обновлен',
        });
      },
      onError: (error: { error: { message: string; code: string } }) => {
        if (error.error.code === 'branch_not_exist_exception') {
          this.#messageService.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: 'Филиал не существует',
          });
          return;
        }

        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.error.message,
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BRANCHES],
        });
      },
    });
  }

  deleteBranch() {
    return this.#mutation({
      mutationFn: (body: number) =>
        this.#http.post<void>(
          environment.apiUrlPrefix + 'nbrk/delete-branch',
          new URLSearchParams({
            request: JSON.stringify(body),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        ),

      onSuccess: () => {
        this.#messageService.add({
          severity: 'success',
          detail: 'Филиал удален',
        });

        this.#router.navigate(['/branches']);
      },
      onError: (error: { error: { message: string; code: string } }) => {
        if (error.error.code === 'branch_not_exist_exception') {
          this.#messageService.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: 'Филиал не существует',
          });
          return;
        }

        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: error.error.message,
        });
      },
      onSettled: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.BRANCHES],
        });
      },
    });
  }
}
