<app-page-layout title="Добавить монету" [breadcrumbItems]="breadcrumbItems">
  <form
    [formGroup]="coinForm"
    (ngSubmit)="onSubmit()"
    class="w-full flex flex-col gap-4 pb-11"
  >
    <div class="flex flex-col items-start gap-2">
      <label for="coinName" class="font-semibold"> Наименование </label>
      <input
        class="w-full"
        pInputText
        type="text"
        placeholder="Название монеты"
        id="coinName"
        formControlName="coinName"
      />
      @if (coinForm.get('coinName')?.invalid &&
      coinForm.get('coinName')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      }
    </div>
    <div class="flex flex-col items-start gap-2">
      <label for="nbrkCoinId" class="font-semibold"> ID монеты НБРК </label>
      <input
        class="w-full"
        pInputText
        type="text"
        placeholder="Название монеты"
        id="nbrkCoinId"
        formControlName="nbrkCoinId"
      />
      @if (coinForm.get('nbrkCoinId')?.invalid &&
      coinForm.get('nbrkCoinId')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="nominal" class="font-semibold"> Номинал (тенге) </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Номинал"
        id="nominal"
        formControlName="nominal"
      />
      @if (coinForm.get('nominal')?.errors?.['required'] &&
      coinForm.get('nominal')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      } @if (coinForm.get('nominal')?.errors?.['min'] &&
      coinForm.get('nominal')?.touched) {
      <small class="text-red-400"> Номинал должен быть больше 0 </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="weight" class="font-semibold"> Вес (oz) </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Вес"
        id="weight"
        formControlName="weight"
      />
      @if (coinForm.get('weight')?.invalid && coinForm.get('weight')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      }@if (coinForm.get('nominal')?.errors?.['min'] &&
      coinForm.get('nominal')?.touched) {
      <small class="text-red-400"> Номинал должен быть больше 0 </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="diameter" class="font-semibold"> Диаметр </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Диаметр"
        id="diameter"
        formControlName="diameter"
      />
      @if (coinForm.get('diameter')?.invalid &&
      coinForm.get('diameter')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      } @if (coinForm.get('diameter')?.errors?.['min'] &&
      coinForm.get('diameter')?.touched) {
      <small class="text-red-400"> Диаметр должен быть больше 0 </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="metal" class="font-semibold"> Содержание металла </label>
      <input
        class="w-full"
        pInputText
        type="text"
        placeholder="Металл"
        id="metal"
        formControlName="metal"
      />

      @if (coinForm.get('metal')?.invalid && coinForm.get('metal')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="quality" class="font-semibold"> Качество </label>
      <input
        class="w-full"
        pInputText
        type="text"
        placeholder="Качество"
        id="quality"
        formControlName="quality"
      />

      @if (coinForm.get('quality')?.invalid && coinForm.get('quality')?.touched)
      {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="availability" class="font-semibold"> Тираж (шт) </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Наличие"
        id="availability"
        formControlName="availability"
      />

      @if (coinForm.get('availability')?.invalid &&
      coinForm.get('availability')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      } @if (coinForm.get('availability')?.errors?.['min'] &&
      coinForm.get('availability')?.touched) {
      <small class="text-red-400"> Поле должно быть больше 0 </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="buyLimit" class="font-semibold">
        Ограничение на покупку (шт)
      </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Лимит покупок"
        id="buyLimit"
        formControlName="buyLimit"
      />

      @if (coinForm.get('buyLimit')?.invalid &&
      coinForm.get('buyLimit')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      } @if (coinForm.get('buyLimit')?.errors?.['min'] &&
      coinForm.get('buyLimit')?.touched) {
      <small class="text-red-400"> Поле должно быть больше 0 </small>
      }
    </div>

    <div class="flex flex-col items-start gap-2">
      <label for="limitMonths" class="font-semibold">
        Временной отрезок (мес)
      </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Лимит месяцев"
        id="limitMonths"
        formControlName="limitMonths"
      />

      @if (coinForm.get('limitMonths')?.invalid &&
      coinForm.get('limitMonths')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      } @if (coinForm.get('limitMonths')?.errors?.['min'] &&
      coinForm.get('limitMonths')?.touched) {
      <small class="text-red-400"> Поле должно быть больше 0 </small>
      }
    </div>
    <div class="flex flex-col items-start gap-2">
      <label for="invCoinQuantity" class="font-semibold">
        Количество цифровых монет (шт)
      </label>
      <p-inputNumber
        styleClass="w-full"
        class="w-full"
        placeholder="Количество монет"
        id="invCoinQuantity"
        formControlName="invCoinQuantity"
      />

      @if (coinForm.get('invCoinQuantity')?.invalid &&
      coinForm.get('invCoinQuantity')?.touched) {
      <small class="text-red-400"> Поле обязательно для заполнения </small>
      } @if (coinForm.get('invCoinQuantity')?.errors?.['min'] &&
      coinForm.get('invCoinQuantity')?.touched) {
      <small class="text-red-400"> Поле должно быть больше 0 </small>
      }
    </div>
    <div class="flex items-center gap-8">
      <div class="flex flex-col items-start gap-2">
        <label for="frontPic" class="font-semibold">
          Фото монеты (передней стороны)
        </label>

        <p-fileUpload
          mode="basic"
          chooseLabel="Выберите изображение"
          chooseIcon="pi pi-upload"
          name="frontPic"
          accept="image/png, image/jpeg"
          maxFileSize="1000000"
          class="p-file-upload-secondary"
          (onSelect)="onSelect($event, 'frontPic')"
        />

        @if (coinForm.get('frontPic')?.invalid &&
        coinForm.get('frontPic')?.touched) {
        <small class="text-red-400"> Поле обязательно для заполнения </small>
        }
      </div>
      <div class="flex flex-col items-start gap-2">
        <label for="backPic" class="font-semibold">
          Фото монеты (задней стороны)
        </label>
        <!-- only accept png and jpg -->
        <p-fileUpload
          mode="basic"
          chooseLabel="Выберите изображение"
          chooseIcon="pi pi-upload"
          name="backPic"
          accept="image/png, image/jpeg"
          maxFileSize="1000000"
          class="p-file-upload-secondary"
          (onSelect)="onSelect($event, 'backPic')"
        />

        @if (coinForm.get('backPic')?.invalid &&
        coinForm.get('backPic')?.touched) {
        <small class="text-red-400"> Поле обязательно для заполнения </small>
        }
      </div>
    </div>

    <div class="flex items-center gap-4">
      <p-button type="submit" styleClass="font-bold">Сохранить</p-button>
      <a routerLink="/coins" class="p-button p-button-outlined font-bold">
        Назад
      </a>
    </div>
  </form>
</app-page-layout>
