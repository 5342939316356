import { DatePipe } from '@angular/common';
import {
  Component,
  computed,
  inject,
  SecurityContext,
  signal,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PageLayoutComponent } from '@components/layout/page-layout/page-layout.component';
import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { OrderReportService } from 'src/app/services/order-report.service';
import { OrdersService } from 'src/app/services/orders.service';
import {
  Permissions,
  PermissionsService,
} from 'src/app/services/permissions.service';
import { OrderOtpComponent } from '../../components/order-otp/order-otp.component';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [
    PageLayoutComponent,
    SkeletonModule,
    ButtonModule,
    DatePipe,
    OrderOtpComponent,
  ],
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.css',
})
export class OrderDetailsComponent {
  id = inject(ActivatedRoute).snapshot.paramMap.get('id');
  permissions = inject(PermissionsService).permissions;
  orderDetails = inject(OrdersService).getOrderById(Number(this.id)).result;
  receiveCoin = inject(OrdersService).receiveCoin();
  receiveCoinResult = this.receiveCoin.result;
  generateOrderReport = inject(OrderReportService).getReport();
  geneateOrderReportResult = this.generateOrderReport.result;
  route = inject(ActivatedRoute);
  sanitizer = inject(DomSanitizer);
  messageService = inject(MessageService);
  isOtpSubmitSuccess = signal(false);
  showOtp = computed(
    () =>
      this.orderDetails().data?.status === 'PAID' && !this.isOtpSubmitSuccess()
  );
  breadcrumbItems: MenuItem[] = [
    {
      label: 'Заказы',
      routerLink: ['/orders'],
      queryParams: {
        ...this.route.snapshot.queryParams,
      },
    },
    {
      label: 'Заказ',
      routerLink: ['/orders', this.id],
    },
  ];
  showCtas = computed(() =>
    this.permissions().includes(Permissions.CAN_MODIFY_ORDERS)
  );

  onReceiveCoin() {
    this.receiveCoin.mutate({ id: Number(this.id) });
  }

  onGenerateOrderReport() {
    this.generateOrderReport.mutate(
      { id: Number(this.id) },
      {
        onSuccess: (res) => {
          const pdf = new Blob([res], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(pdf);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          const url = this.sanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)
          );
          if (!url) {
            this.messageService.add({
              severity: 'error',
              summary: 'Ошибка',
              detail: 'Не удалось сгенерировать отчет (ошибка URL)',
            });
            return;
          }
          iframe.src = url;
          document.body.appendChild(iframe);
          const contentWindow = iframe.contentWindow;
          if (!contentWindow) {
            this.messageService.add({
              severity: 'error',
              summary: 'Ошибка',
              detail: 'Не удалось сгенерировать отчет (ошибка contentWindow)',
            });
            return;
          }
          contentWindow.print();
        },
      }
    );
  }

  onOtpSubmitSuccess() {
    this.isOtpSubmitSuccess.set(true);
  }

  onOtpSubmitError() {
    this.isOtpSubmitSuccess.set(false);
  }
}
