import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';

export const nbrkManagerGuard: CanActivateFn = async (route, state) => {
  const authorizationService = inject(AuthorizationService);
  const router = inject(Router);

  try {
    const authInfo = await authorizationService
      .getUserInfoMutation()
      .mutateAsync({});

    if (!authInfo || !authInfo.accountName) {
      return false;
    }

    const role = authInfo.role;

    if (role === 'NBRK_MANAGER') {
      router.navigate(['/products']);
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);

    return false;
  }
};
