import { NbrkRoles } from '@model/nbrk-roles';

export const QUERY_KEYS = {
  COINS: 'coins',
  USER_INFO: 'user_info',
  FILE: 'file',
  ORDERS: 'orders',
  USERS: 'users',
  BRANCHES: 'branches',
  BRANCH: 'branch',
  BRANCH_BALANCE: 'branch_balance',
  BRANCH_BALANCE_LIST: 'branch_balance_list',
  OWN_BRANCH: 'own_branch',
};

export const LOCALSTORAGE_KEYS = {
  SESSION_ID: 'sessionId',
  GG_TOKEN: 'gg_token',
};

export const userObject = {
  accountName: 'nbrk.test8',
  personGuid: null,
  displayName: 'TEST NBRK',
  role: 'NBRK_ADMIN',
  roleName: 'NBRK_ADMIN',
  sessionId: 'D33PwMtWbyqvMFsh0-210e93752ea0e954f136',
  token: 'XYff98Y8G3XwNs6yw',
  privilegesList: [],
};

export const rolesDropdownOptions: {
  name: string;
  code: NbrkRoles | '';
}[] = [
  {
    name: 'Выберите тип пользователя',
    code: '',
  },
  {
    name: NbrkRoles.NBRK_ADMIN,
    code: NbrkRoles.NBRK_ADMIN,
  },
  {
    name: NbrkRoles.NBRK_MANAGER,
    code: NbrkRoles.NBRK_MANAGER,
  },
  {
    name: NbrkRoles.NBRK_AUDITOR,
    code: NbrkRoles.NBRK_AUDITOR,
  },
];
