import { Component, computed, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ScrollerModule } from 'primeng/scroller';
import { AixLogoComponent } from '../aix-logo/aix-logo.component';
import { NbrkLogoComponent } from '../nbrk-logo/nbrk-logo.component';
import {
  Permissions,
  PermissionsService,
} from 'src/app/services/permissions.service';

@Component({
  selector: 'app-sidebar-content',
  standalone: true,
  imports: [
    ScrollerModule,
    RouterLink,
    RouterLinkActive,
    AixLogoComponent,
    NbrkLogoComponent,
  ],
  templateUrl: './sidebar-content.component.html',
  styleUrl: './sidebar-content.component.css',
})
export class SidebarContentComponent {
  permissions = inject(PermissionsService).permissions;

  linkItems = computed(() =>
    [
      {
        label: 'Роли',
        routerLink: 'roles',
      },
      {
        label: 'Филиалы',
        routerLink: 'branches',
      },
      {
        label: 'Товары',
        routerLink: 'products',
      },
      {
        label: 'Заказы',
        routerLink: 'orders',
      },
      {
        label: 'Монеты',
        routerLink: 'coins',
      },
    ].filter((item) => {
      if (item.routerLink === 'orders') {
        if (
          this.permissions().includes(Permissions.CAN_VIEW_ORDERS) ||
          this.permissions().includes(Permissions.CAN_VIEW_OWN_ORDERS)
        ) {
          return true;
        }
        return false;
      }

      if (item.routerLink === 'coins') {
        if (this.permissions().includes(Permissions.CAN_VIEW_COINS)) {
          return true;
        }
        return false;
      }

      if (item.routerLink === 'products') {
        if (this.permissions().includes(Permissions.CAN_VIEW_PRODUCTS)) {
          return true;
        }
        return false;
      }

      if (item.routerLink === 'branches') {
        if (this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)) {
          return true;
        }
        return false;
      }

      if (item.routerLink === 'roles') {
        if (this.permissions().includes(Permissions.CAN_VIEW_USERS)) {
          return true;
        }
        return false;
      }

      return true;
    })
  );

  asLinkItem(item: any) {
    return item as { label: string; routerLink: string };
  }
}
