<form [formGroup]="otpForm" (ngSubmit)="onSubmit()" class="flex flex-col gap-8">
  <div class="flex flex-col gap-3">
    <label for="otp" class="font-semibold"> Код для получения </label>
    <p-inputOtp
      formControlName="otp"
      [length]="4"
      [class.ng-dirty.ng-invalid]="confirmOtpResult().isError"
      (keydown.enter)="onSubmit()"
    />

    @if (confirmOtpResult().isError) {
    <p class="text-red-400">Код не верный</p>
    }
  </div>

  <p-button
    [label]="'Проверить'"
    type="submit"
    [disabled]="!otpForm.valid || confirmOtpResult().isPending"
  />
</form>
