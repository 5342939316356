import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { injectQuery } from '@ngneat/query';
import { QUERY_KEYS } from '../lib/constants';
import { AuthorizationService } from '../lib/core/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class OwnBranchService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #authInfo = inject(AuthorizationService).authInfo;

  getOwnBranch() {
    const accountName = computed(() => this.#authInfo()?.accountName || '');

    return this.#query({
      queryKey: [QUERY_KEYS.OWN_BRANCH, accountName()],
      queryFn: () =>
        this.#http.get<number>(environment.apiUrlPrefix + 'nbrk/own-branch', {
          withCredentials: true,
          params: {
            account: accountName(),
          },
        }),
    });
  }
}
