import { Component, computed, inject, output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { BranchBalanceService } from 'src/app/services/branch-balance.service';
import { CoinService } from 'src/app/services/coin.service';

@Component({
  selector: 'app-create-product',
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    ButtonModule,
    RouterLink,
    InputNumberModule,
  ],
  templateUrl: './create-product.component.html',
})
export class CreateProductComponent {
  onSubmit = output<void>();
  onClose = output<void>();
  coins = inject(CoinService).getCoins().result;
  coinDropdownOptions = computed(() =>
    this.coins().data?.map((coin) => ({ label: coin.coinName, value: coin.id }))
  );
  branchId = inject(ActivatedRoute).snapshot.paramMap.get('id');
  productDetailsForm = new FormGroup({
    coinId: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
  });
  saveBranchBalance = inject(BranchBalanceService).saveBranchBalance();
  saveBranchBalanceResult = this.saveBranchBalance.result;

  handleSubmit() {
    this.productDetailsForm.markAllAsTouched();
    if (!this.productDetailsForm.valid) {
      return;
    }

    this.saveBranchBalance.mutate(
      {
        body: {
          branchId: +this.branchId!,
          coinId: +this.productDetailsForm.get('coinId')?.value!,
          quantity: +this.productDetailsForm.get('quantity')?.value!,
        },
      },
      {
        onSuccess: () => {
          this.onSubmit.emit();
        },
      }
    );
  }
  handleClose() {
    this.onClose.emit();
  }
}
