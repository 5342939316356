import { Component, computed, inject } from '@angular/core';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { TableColumn } from '@model/table-column';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { OrdersService } from 'src/app/services/orders.service';
import { SkeletonModule } from 'primeng/skeleton';
import { FiltersComponent } from './filters/filters.component';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import { DataEntity } from '@model/orders';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OrderFilters } from '@model/order-filters';
import {
  Permissions,
  PermissionsService,
} from 'src/app/services/permissions.service';
import { OwnBranchService } from 'src/app/services/own-branch.service';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    HeadingOneComponent,
    TableModule,
    SkeletonModule,
    FiltersComponent,
    PageLayoutComponent,
    RouterLink,
    DatePipe,
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
})
export class OrdersComponent {
  private ordersService = inject(OrdersService);
  ordersDef = inject(OrdersService).getOrders();
  orders = this.ordersDef.result;
  route = inject(ActivatedRoute);
  router = inject(Router);
  permissions = inject(PermissionsService).permissions;
  ownBranchIdDef = inject(OwnBranchService).getOwnBranch().result$;
  ownBranchId: string | null = null;

  filters = {
    status: null,
    fio: null,
    iin: null,
    branch: null,
    pageNumber: 0,
    pageSize: 10,
    first: 0,
  } as OrderFilters;

  totalRecords = computed(() => this.orders().data?.total || 0);

  constructor() {
    this.route.queryParams.subscribe((params) => {
      this.filters = {
        fio: params['fio'] || null,
        iin: params['iin'] || null,
        status: params['status'] || null,
        branch: params['branch'] || null,
        pageNumber: params['pageNumber'] || 0,
        pageSize: params['pageSize'] || 10,
        first: params['first'] || 0,
      };

      this.ownBranchIdDef.subscribe((res) => {
        this.ownBranchId = res.data?.toString() || null;
        this.fetchOrders();
      });
    });
  }

  getBranchFilter() {
    if (!this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)) {
      return this.ownBranchId;
    }

    if (this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)) {
      return this.filters.branch;
    }

    return null;
  }

  cols: TableColumn<DataEntity>[] = [
    { field: 'id', header: 'N#' },
    { field: 'dateOfReceipt', header: 'Дата' },
    { field: 'fio', header: 'ФИО' },
    { field: 'iin', header: 'ИИН' },
    { field: 'status', header: 'Статус' },
    { field: 'quantity', header: 'Кол-во' },
    { field: 'price', header: 'Стоимость' },
    { field: 'departmentId', header: 'Филиал' },
  ];

  onLazyLoad(event: TableLazyLoadEvent) {
    this.filters.first = event.first || 0;
    this.filters.pageSize = event.rows || 10;
    this.filters.pageNumber = (event.first || 0) / (event.rows || 10) || 0;
    this.router.navigate([], {
      queryParams: this.filters,
    });
  }

  fetchOrders() {
    this.ordersDef.updateOptions(
      this.ordersService.getOrdersOptions({
        params: {
          filter: {
            ...this.filters,
            branch: this.getBranchFilter(),
          },
        },
      })
    );
  }
}
