import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { injectMutation, injectQuery } from '@ngneat/query';
import { LOCALSTORAGE_KEYS, QUERY_KEYS, userObject } from '../constants';
import { environment } from '@environment/environment';
import { AuthInfo } from '@model/auth-info';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();

  authInfo = signal<AuthInfo | null>(null);

  constructor() {}

  getUserInfo() {
    if (!environment.production) {
      return this.#query({
        queryKey: [QUERY_KEYS.USER_INFO],
        queryFn: () => {
          this.authInfo.set(userObject);
          return userObject;
        },
      });
    }

    return this.#query({
      queryKey: [QUERY_KEYS.USER_INFO],
      queryFn: () =>
        this.#http.get<AuthInfo>(environment.apiUrlPrefix + 'auth/info', {
          withCredentials: true,
        }),
    });
  }

  getUserInfoMutation() {
    if (!environment.production) {
      return this.#mutation({
        mutationFn: () => {
          return Promise.resolve(userObject);
        },
        onSuccess: (res) => {
          this.authInfo.set(res);
        },
      });
    }

    return this.#mutation({
      mutationFn: () =>
        this.#http.get<AuthInfo>(environment.apiUrlPrefix + 'auth/info', {
          withCredentials: true,
        }),
      onSuccess: (res) => {
        this.authInfo.set(res);
      },
    });
  }

  logout() {
    return this.#mutation({
      mutationFn: () =>
        this.#http.get(environment.apiUrlPrefix + 'auth/logout', {
          withCredentials: true,
        }),

      onSettled: () => {
        localStorage.removeItem(LOCALSTORAGE_KEYS.GG_TOKEN);
        window.location.href = environment.backofficeUrl;
      },
    });
  }
}
