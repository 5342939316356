<app-page-layout [breadcrumbItems]="items" title="Инвестиционная монета">
  @if (coinDetails().isError) {
  <p class="text-red-400">
    {{ coinDetails().error?.message || "Что-то пошло не так" }}
  </p>
  } @if (coinDetails().isLoading) {
  <div class="grid grid-cols-2 gap-12">
    <p-skeleton height="552px" width="384px" />
    <p-skeleton height="128px" width="384px" />
  </div>
  } @if(!!coinDetails().isSuccess) {
  <div class="grid grid-cols-2 gap-12">
    <form [formGroup]="coinForm" class="w-full max-w-96">
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Наименование</h4>
        @if (editMode) {
        <input
          class="w-full"
          pInputText
          type="text"
          placeholder="Наименование"
          id="coinName"
          formControlName="coinName"
        />
        } @else {
        <span class="border p-3">
          {{ coinDetails().data?.coinName }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">ID монеты НБРК</h4>
        @if (editMode) {
        <input
          class="w-full"
          pInputText
          type="text"
          placeholder="Наименование"
          id="nbrkCoinId"
          formControlName="nbrkCoinId"
        />
        } @else {
        <span class="border p-3">
          {{ coinDetails().data?.nbrkCoinId }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Номинал (тенге)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="nominal"
          placeholder="Номинал"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.nominal | number }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Вес (oz)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="weight"
          placeholder="Вес"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.weight | number }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Диаметр/размер (мм)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="diameter"
          placeholder="Диаметр"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.diameter | number }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Содержание металла</h4>
        @if (editMode) {
        <input
          class="w-full"
          pInputText
          type="text"
          placeholder="Содержание металла"
          id="metal"
          formControlName="metal"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.metal }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Качество</h4>
        @if (editMode) {
        <input
          class="w-full"
          pInputText
          type="text"
          placeholder="Качество"
          id="quality"
          formControlName="quality"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.quality }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Тираж (шт)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="availability"
          placeholder="Тираж"
        />
        } @else {
        <span class="border p-3">
          {{ coinDetails().data?.availability | number }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Ограничение на покупку (шт)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="buyLimit"
          placeholder="Ограничение на покупку"
        />
        } @else {
        <span class="border p-3">
          {{ coinDetails().data?.buyLimit | number }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Временной отрезок (мес)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="limitMonths"
          placeholder="Временный отрезок"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.limitMonths | number }}
        </span>
        }
      </div>
      <div class="grid grid-cols-2 w-full">
        <h4 class="font-semibold border p-3">Количество цифровых монет (шт)</h4>
        @if (editMode) {
        <p-inputNumber
          class="w-full h-full first:w-full"
          formControlName="invCoinQuantity"
          placeholder="Количество монет"
        />
        } @else {

        <span class="border p-3">
          {{ coinDetails().data?.invCoinQuantity | number }}
        </span>
        }
      </div>
    </form>

    @if (!frontPic || !backPic) {
    <div class="flex items-start gap-4">
      <div class="flex flex-col gap-3">
        <p-skeleton height="128px" width="128px" />
        @if (editMode) {
        <p-fileUpload
          mode="basic"
          (onSelect)="onSelect($event, 'frontPic')"
          class="p-file-upload-secondary"
          chooseIcon="pi pi-upload"
          accept="image/png, image/jpeg"
          maxFileSize="1000000"
        />
        }
      </div>
      <div class="flex flex-col gap-3">
        <p-skeleton height="128px" width="128px" />
        @if (editMode) {
        <p-fileUpload
          mode="basic"
          (onSelect)="onSelect($event, 'backPic')"
          class="p-file-upload-secondary"
          chooseIcon="pi pi-upload"
          accept="image/png, image/jpeg"
          maxFileSize="1000000"
        />
        }
      </div>
    </div>

    } @else {
    <div class="flex items-start gap-4">
      <div class="flex flex-col gap-3">
        <img
          [src]="frontPic"
          alt="Front picture"
          class="size-32 object-contain"
        />
        @if (editMode) {
        <p-fileUpload
          mode="basic"
          (onSelect)="onSelect($event, 'frontPic')"
          class="p-file-upload-secondary"
          chooseIcon="pi pi-upload"
          accept="image/png, image/jpeg"
          maxFileSize="1000000"
        />
        }
      </div>
      <div class="flex flex-col gap-3">
        <img
          [src]="backPic"
          alt="Back picture"
          class="size-32 object-contain"
        />
        @if (editMode) {
        <p-fileUpload
          mode="basic"
          (onSelect)="onSelect($event, 'backPic')"
          class="p-file-upload-secondary"
          chooseIcon="pi pi-upload"
          accept="image/png, image/jpeg"
          maxFileSize="1000000"
        />
        }
      </div>
    </div>
    }
  </div>
  }

  <div class="w-full flex items-center gap-4">
    @if (!editMode) { @if (shouldDisplayEditCta()) {

    <p-button
      [label]="'Редактировать'"
      [size]="'small'"
      (click)="edit()"
    ></p-button>
    } } @else {
    <p-button
      [label]="'Сохранить'"
      [size]="'small'"
      (click)="save()"
    ></p-button>
    <p-button
      [outlined]="true"
      [label]="'Отмена'"
      [size]="'small'"
      (click)="cancel()"
    ></p-button>

    }
  </div>
</app-page-layout>
