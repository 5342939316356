<app-page-layout title="Пользователь НБРК" [breadcrumbItems]="breadcrumbItems">
  @if (user().isError) {
  <p class="text-red-400">
    {{ user().error?.message || "Что-то пошло не так" }}
  </p>
  } @if (user().isLoading) {
  <div class="grow flex gap-12 flex-col">
    <p-skeleton height="180px" width="672px" />
    <p-skeleton height="156px" width="672px" />
  </div>
  } @if(user().isSuccess){

  <div class="grow">
    <form
      [formGroup]="roleDetailsForm()"
      (ngSubmit)="onSubmit()"
      class="flex flex-col gap-12 max-w-2xl"
    >
      <section class="w-full grid grid-cols-2 gap-8">
        <div class="flex flex-col items-start gap-2">
          <label class="font-semibold" for="type"> Тип пользователя </label>
          <p-dropdown
            class="w-full"
            styleClass="w-full"
            [options]="userTypes"
            (ngModelChange)="onTypeChange()"
            id="type"
            formControlName="type"
            placeholder="Выберите тип пользователя"
            optionLabel="name"
            optionValue="code"
            [class.ng-dirty.ng-invalid]="
              roleDetailsForm().get('type')?.invalid &&
              (roleDetailsForm().get('type')?.touched ||
                roleDetailsForm().get('type')?.dirty)
            "
          />

          <small
            *ngIf="
              roleDetailsForm().get('type')?.invalid &&
              (roleDetailsForm().get('type')?.touched ||
                roleDetailsForm().get('type')?.dirty)
            "
            class="text-red-400"
          >
            Поле обязательно для заполнения
          </small>
        </div>
        <div class="flex flex-col items-start gap-2">
          <label class="font-semibold" for="fullName"> ФИО </label>
          <input
            class="w-full"
            pInputText
            type="text"
            id="fullName"
            formControlName="fullName"
            placeholder="ФИО"
            [class.ng-dirty.ng-invalid]="
              roleDetailsForm().get('fullName')?.invalid &&
              (roleDetailsForm().get('fullName')?.touched ||
                roleDetailsForm().get('fullName')?.dirty)
            "
          />

          <small
            *ngIf="
              roleDetailsForm().get('fullName')?.invalid &&
              (roleDetailsForm().get('fullName')?.touched ||
                roleDetailsForm().get('fullName')?.dirty)
            "
            class="text-red-400"
          >
            Поле обязательно для заполнения
          </small>
        </div>

        @if (showBranchAndCity() || roleDetailsForm().get('type')?.value ===
        'NBRK_MANAGER') {
        <div class="flex flex-col items-start gap-2">
          <label class="font-semibold" for="branchId"> Филиал </label>
          <p-dropdown
            class="w-full"
            styleClass="w-full"
            [options]="branchIds()"
            id="branchId"
            (ngModelChange)="onBranchChange()"
            formControlName="branchId"
            placeholder="Выберите филиал"
            optionLabel="name"
            optionValue="code"
            [class.ng-dirty.ng-invalid]="
              roleDetailsForm().get('branchId')?.invalid &&
              (roleDetailsForm().get('branchId')?.touched ||
                roleDetailsForm().get('branchId')?.dirty)
            "
          />

          <small
            *ngIf="
              roleDetailsForm().get('branchId')?.invalid &&
              (roleDetailsForm().get('branchId')?.touched ||
                roleDetailsForm().get('branchId')?.dirty)
            "
            class="text-red-400"
          >
            Поле обязательно для заполнения
          </small>
        </div>

        <div class="flex flex-col items-start gap-2">
          <label class="font-semibold" for="city"> Город </label>
          <input
            class="w-full"
            pInputText
            type="text"
            id="city"
            readonly
            formControlName="city"
            placeholder="Город"
            [class.ng-dirty.ng-invalid]="
              roleDetailsForm().get('city')?.invalid &&
              (roleDetailsForm().get('city')?.touched ||
                roleDetailsForm().get('city')?.dirty)
            "
          />

          <small
            *ngIf="
              roleDetailsForm().get('city')?.invalid &&
              (roleDetailsForm().get('city')?.touched ||
                roleDetailsForm().get('city')?.dirty)
            "
            class="text-red-400"
          >
            Поле обязательно для заполнения
          </small>
        </div>
        }
      </section>

      <section class="flex flex-col items-start gap-2 w-full">
        <section class="flex items-end gap-8">
          <div class="flex flex-col items-start gap-2">
            <label class="font-semibold" for="email"> Email </label>
            <input
              class=""
              pInputText
              type="email"
              id="email"
              formControlName="email"
              placeholder="Email"
              [class.ng-dirty.ng-invalid]="
                roleDetailsForm().get('email')?.invalid &&
                (roleDetailsForm().get('email')?.touched ||
                  roleDetailsForm().get('email')?.dirty)
              "
            />

            <small
              *ngIf="
                    roleDetailsForm().get('email')?.errors?.['required'] &&
                    (roleDetailsForm().get('email')?.touched ||
                      roleDetailsForm().get('email')?.dirty)
                  "
              class="text-red-400"
            >
              Поле обязательно для заполнения
            </small>
            <small
              *ngIf="
                    roleDetailsForm().get('email')?.errors?.['email'] &&
                    (roleDetailsForm().get('email')?.touched ||
                      roleDetailsForm().get('email')?.dirty)
                  "
              class="text-red-400"
            >
              Введите корректный email
            </small>
          </div>
          <p-button
            [text]="true"
            [disabled]="roleDetailsForm().get('email')?.value === ''"
            (onClick)="copyText(roleDetailsForm().get('email')?.value || '')"
            icon="pi pi-copy"
            label="Скопировать"
            [styleClass]="
              roleDetailsForm().get('email')?.invalid &&
              (roleDetailsForm().get('email')?.touched ||
                roleDetailsForm().get('email')?.dirty)
                ? 'mb-7'
                : ''
            "
          >
          </p-button>
        </section>

        <section class="flex items-end gap-8">
          <div class="flex flex-col items-start gap-2 w-full">
            <label class="font-semibold" for="password">
              Изменить пароль
            </label>
            <p-password
              class=""
              type="password"
              inputId="password"
              [feedback]="false"
              formControlName="password"
              placeholder="Новый пароль"
              [class.ng-dirty.ng-invalid]="
                roleDetailsForm().get('password')?.invalid &&
                (roleDetailsForm().get('password')?.touched ||
                  roleDetailsForm().get('password')?.dirty)
              "
            />

            <small
              *ngIf="
                roleDetailsForm().get('password')?.invalid &&
                (roleDetailsForm().get('password')?.touched ||
                  roleDetailsForm().get('password')?.dirty)
              "
              class="text-red-400"
            >
              Поле обязательно для заполнения
            </small>
          </div>

          <p-button
            [text]="true"
            (onClick)="copyText(roleDetailsForm().get('password')?.value || '')"
            icon="pi pi-copy"
            label="Скопировать"
            [disabled]="roleDetailsForm().get('password')?.value === ''"
            [styleClass]="
              roleDetailsForm().get('password')?.invalid &&
              (roleDetailsForm().get('password')?.touched ||
                roleDetailsForm().get('password')?.dirty)
                ? 'mb-7'
                : ''
            "
          >
          </p-button>
        </section>
      </section>

      <div class="flex items-center gap-4">
        <p-button type="submit" label="Сохранить"></p-button>
        <a routerLink="/roles" class="p-button p-button-outlined font-bold">
          Назад
        </a>
      </div>
    </form>
  </div>
  }
</app-page-layout>
