import { Component, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { CoinBody } from '@model/coin';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { CoinService } from 'src/app/services/coin.service';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { MenuItem, MessageService } from 'primeng/api';
import { FileService } from 'src/app/services/file.service';
import { toBase64 } from '@utils/to-base64';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';

@Component({
  selector: 'app-create-coin',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    HeadingOneComponent,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    RouterLink,
    FileUploadModule,
    BreadcrumbModule,
    PageLayoutComponent,
  ],
  templateUrl: './create-coin.component.html',
  styleUrl: './create-coin.component.css',
})
export class CreateCoinComponent {
  saveCoin = inject(CoinService).saveCoin();
  uploadFile = inject(FileService).uploadFile();
  messageService = inject(MessageService);
  breadcrumbItems: MenuItem[] = [
    {
      label: 'Монеты',
      routerLink: ['/coins'],
    },
    {
      label: 'Добавить монету',
      routerLink: ['/create-coin'],
    },
  ];

  coinForm: FormGroup = new FormGroup({
    coinName: new FormControl('', [Validators.required]),
    nbrkCoinId: new FormControl('', [Validators.required]),
    nominal: new FormControl(0, [Validators.required, Validators.min(1)]),
    weight: new FormControl(0, [Validators.required]),
    diameter: new FormControl(0, [Validators.required, Validators.min(1)]),
    metal: new FormControl('', [Validators.required]),
    quality: new FormControl('', [Validators.required]),
    frontPic: new FormControl('', [Validators.required]),
    backPic: new FormControl('', [Validators.required]),
    availability: new FormControl(0, [Validators.required, Validators.min(1)]),
    buyLimit: new FormControl(0, [Validators.required, Validators.min(1)]),
    limitMonths: new FormControl(0, [Validators.required, Validators.min(1)]),
    invCoinQuantity: new FormControl(0, [
      Validators.required,
      Validators.min(1),
    ]),
  });

  async onSelect(event: FileSelectEvent, field: 'frontPic' | 'backPic') {
    if (event.files && event.files[0]) {
      const file = event.files[0];

      let src = await toBase64(file);
      src = src.replace('data:' + file.type + ';base64,', '');

      const res = await this.uploadFile.mutateAsync({
        file,
        fileId: file.name,
        src,
      });

      if (field === 'frontPic') {
        this.coinForm.get('frontPic')?.setValue(res);
      } else {
        this.coinForm.get('backPic')?.setValue(res);
      }
    }
  }

  onSubmit() {
    if (this.coinForm.invalid) {
      this.coinForm.markAllAsTouched();
      return;
    }

    const coin: CoinBody = {
      coinName: this.coinForm.get('coinName')?.value,
      nbrkCoinId: this.coinForm.get('nbrkCoinId')?.value,
      nominal: this.coinForm.get('nominal')?.value,
      weight: this.coinForm.get('weight')?.value,
      diameter: this.coinForm.get('diameter')?.value,
      metal: this.coinForm.get('metal')?.value,
      quality: this.coinForm.get('quality')?.value,
      frontPic: this.coinForm.get('frontPic')?.value,
      backPic: this.coinForm.get('backPic')?.value,
      availability: this.coinForm.get('availability')?.value,
      buyLimit: this.coinForm.get('buyLimit')?.value,
      limitMonths: this.coinForm.get('limitMonths')?.value,
      invCoinQuantity: this.coinForm.get('invCoinQuantity')?.value,
    };
    this.saveCoin.mutate({ coinBody: coin });
  }
}
