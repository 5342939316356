import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinService } from 'src/app/services/coin.service';
import { HeadingOneComponent } from '../../components/heading-one/heading-one.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { FileService } from 'src/app/services/file.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CoinBody } from '@model/coin';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { toBase64 } from '@utils/to-base64';
import { DecimalPipe } from '@angular/common';
import { PageLayoutComponent } from '../../components/layout/page-layout/page-layout.component';
import {
  Permissions,
  PermissionsService,
} from 'src/app/services/permissions.service';

@Component({
  selector: 'app-coin-details',
  standalone: true,
  imports: [
    HeadingOneComponent,
    BreadcrumbModule,
    SkeletonModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    ReactiveFormsModule,
    FileUploadModule,
    DecimalPipe,
    PageLayoutComponent,
  ],
  templateUrl: './coin-details.component.html',
  styleUrl: './coin-details.component.css',
})
export class CoinDetailsComponent {
  id = inject(ActivatedRoute).snapshot.paramMap.get('id');
  coinDetails = inject(CoinService).getSingleCoin(this.id as string).result;
  coinDetailsObservable = inject(CoinService).getSingleCoin(this.id as string)
    .result$;
  frontPic: SafeUrl = '';
  backPic: SafeUrl = '';
  sanitizer = inject(DomSanitizer);
  fileService = inject(FileService);
  saveCoin = inject(CoinService).saveCoin();
  uploadFile = inject(FileService).uploadFile();
  permissions = inject(PermissionsService).permissions;
  shouldDisplayEditCta = computed(() =>
    this.permissions().includes(Permissions.CAN_EDIT_COINS)
  );

  editMode = false;
  coinForm = new FormGroup({
    coinName: new FormControl('', [Validators.required]),
    nbrkCoinId: new FormControl('', [Validators.required]),
    nominal: new FormControl(0, [Validators.required, Validators.min(1)]),
    weight: new FormControl(0, [Validators.required]),
    diameter: new FormControl(0, [Validators.required, Validators.min(1)]),
    metal: new FormControl('', [Validators.required]),
    quality: new FormControl('', [Validators.required]),
    frontPic: new FormControl('', [Validators.required]),
    backPic: new FormControl('', [Validators.required]),
    availability: new FormControl(0, [Validators.required, Validators.min(1)]),
    buyLimit: new FormControl(0, [Validators.required, Validators.min(1)]),
    limitMonths: new FormControl(0, [Validators.required, Validators.min(1)]),
    invCoinQuantity: new FormControl(0, [
      Validators.required,
      Validators.min(1),
    ]),
  });

  items: MenuItem[] = [
    {
      label: 'Монеты',
      routerLink: '/coins',
    },
    {
      label: 'Инвестиционная монета',
      routerLink: '/coins/' + this.id,
    },
  ];

  ngOnInit() {
    this.coinDetailsObservable.subscribe((res) => {
      if (res.data) {
        const frontPic = res.data.frontPic;
        const backPic = res.data.backPic;

        this.setFrontCoinPicture(frontPic);
        this.setBackCoinPicture(backPic);
      }
    });
  }

  setFrontCoinPicture(pictureId: string) {
    this.fileService.getResource(pictureId).result$.subscribe((res) => {
      if (res.data) {
        const objectURL = URL.createObjectURL(res.data.body as Blob);
        this.frontPic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
    });
  }

  setBackCoinPicture(pictureId: string) {
    this.fileService.getResource(pictureId).result$.subscribe((res) => {
      if (res.data) {
        const objectURL = URL.createObjectURL(res.data.body as Blob);
        this.backPic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }
    });
  }

  async onSelect(event: FileSelectEvent, field: 'frontPic' | 'backPic') {
    if (event.files && event.files[0]) {
      const file = event.files[0];

      let src = await toBase64(file);
      src = src.replace('data:' + file.type + ';base64,', '');

      const res = await this.uploadFile.mutateAsync({
        file,
        fileId: file.name,
        src,
      });

      if (field === 'frontPic') {
        this.coinForm.get('frontPic')?.setValue(res);
        this.setFrontCoinPicture(res);
      } else {
        this.coinForm.get('backPic')?.setValue(res);
        this.setBackCoinPicture(res);
      }
    }
  }

  edit() {
    this.editMode = true;

    this.coinForm.patchValue({
      ...this.coinDetails().data,
    });
  }

  async save() {
    if (this.coinForm.invalid) {
      this.coinForm.markAllAsTouched();
      return;
    }

    await this.saveCoin.mutateAsync({
      coinBody: {
        ...(this.coinForm.value as CoinBody),
        id: this.coinDetails().data?.id,
      },
    });

    this.editMode = false;
  }

  cancel() {
    this.editMode = false;
  }
}
