import {
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { DropdownModule } from 'primeng/dropdown';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { OrderFilters } from '@model/order-filters';
import { orderStatusMenuItems } from '@model/order-status';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BranchService } from 'src/app/services/branch.service';
import {
  Permissions,
  PermissionsService,
} from 'src/app/services/permissions.service';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    InputTextModule,
    IconFieldModule,
    InputIconModule,
    DropdownModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.css',
})
export class FiltersComponent {
  permissions = inject(PermissionsService).permissions;
  branches = inject(BranchService).getBranchList().result;
  branchDropdownOptions = computed<{ name: string; code: string | null }[]>(
    () => {
      if (!this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)) {
        return [];
      }

      return [
        {
          name: 'Все филиалы',
          code: null,
        },
        ...(this.branches().data?.map((branch) => ({
          name: branch.id.toString(),
          code: branch.id.toString(),
        })) || []),
      ];
    }
  );
  shouldDisplayBranchesFilter = computed(() =>
    this.permissions().includes(Permissions.CAN_VIEW_BRANCHES)
  );
  statuses = orderStatusMenuItems;
  onSubmit = output<OrderFilters>();
  onReset = output<OrderFilters>();

  router = inject(Router);
  route = inject(ActivatedRoute);
  filtersForm = new FormGroup({
    status: new FormControl(
      this.route.snapshot.queryParamMap.get('status') || ''
    ),
    branch: new FormControl(
      this.route.snapshot.queryParamMap.get('branch') || ''
    ),
    iin: new FormControl(this.route.snapshot.queryParamMap.get('iin') || ''),
    fio: new FormControl(this.route.snapshot.queryParamMap.get('fio') || ''),
  });

  submitForm() {
    this.setQueryParams();
    this.onSubmit.emit({
      ...this.filtersForm.value,
      pageNumber: 0,
      pageSize: 10,
      first: 0,
    } as OrderFilters);
  }

  resetFilters() {
    this.filtersForm.reset();
    this.setQueryParams();
    this.onReset.emit(this.filtersForm.value as OrderFilters);
  }

  setQueryParams() {
    const queryParams: Params = {
      ...this.filtersForm.value,
      pageNumber: 0,
      pageSize: 10,
      first: 0,
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
