import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { injectQuery, injectMutation } from '@ngneat/query';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class OrderOtpService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #mutation = injectMutation();
  #messageService = inject(MessageService);

  confirmOtp() {
    return this.#mutation({
      mutationFn: ({
        code,
        orderId,
        phone,
      }: {
        code: string;
        orderId: string;
        phone: string;
      }) =>
        this.#http.post<'true' | 'false'>(
          environment.apiUrlPrefix + 'nbrk/send-receive-gold-confirmation',
          new URLSearchParams({
            request: JSON.stringify({
              notificationCode: code,
              id: orderId,
              confirmationType: 'NOTIFICATION_CODE',
              username: phone,
              tableFrom: 'RECEIVE_GOLD_DATA',
            }),
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        ),

      onSuccess: (res) => {
        if (res === 'false') {
          throw new Error('Неверный код');
        }

        this.#messageService.add({
          severity: 'success',
          detail: 'Код подтвержден',
        });
      },

      onError: (err) => {
        this.#messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: err.message,
        });
      },
    });
  }
}
